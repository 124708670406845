/* ####################################################
                      THEME VARIABLES
#####################################################*/


/* ####################################################
                    END THEME VARIABLES
#####################################################*/

/* ####################################################
                      GLOBAL STYLES
#####################################################*/

@import url('https://fonts.cdnfonts.com/css/mv-boli');

#home-slide-1.swiper-wrapper{
    height: 900px;
}

.swiper-button-next, .swiper-button-prev{
    color: red !important;
}

body{
    background-color: #FEF6D3;
}

h1, h2, h3, h4, h5, p, a, a.nav-link, .form-label{
   color: #F47320;
   text-decoration: none;;                                    
}



h1{
    font-family: 'loce';                                     
}

h2, h3{
    font-family: 'spicy-rice';
}

p, a.nav-link, .form-label{
    font-family: 'spicy-rice'; 
    font-size: 1em;
    letter-spacing: 1px;                            
}

a.footer-link, a.nav1, a.nav2{
    font-family: 'spicy-rice'; 
}

.card, .card-header, .card-body, .card-footer{
    border-radius:0px !important;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
  }
  
  .overlay-container:hover .overlay {
    opacity: 1;
  }

  .floats{
    position: absolute;
    height: 100px;
    width: 100px;
    color: #9560FA;
    margin-top: -150px;

  }

  .modal{
    --bs-modal-bg: #FEF6D3;
  }

/* ####################################################
                    END GLOBAL STYLES
#####################################################*/

/* ####################################################
                      HEADER STYLES
#####################################################*/

.header-color{
    background-color: #FEF6D3;
    background-image: url(../img/leftcorner.svg), url(../img/sun.svg), url(../img/rightcorner.svg);
    background-position: top left, top center, top right;
    background-repeat: no-repeat;
    border-bottom: 4px solid #DDBB71;
    box-shadow: 0px 5px 5px rgba(110, 110, 110, 0.333);
}



.nav1{
    padding-right: 1.5rem;
}

.nav2{
    padding-right: 1rem;
}

.mid-pad{
    width: 160px;
}

.hideable{
    display: flex;
}

.logo{
    height: 6em;
    width: auto;
}

.logo-small{
    height: 4em;
    width: auto;
}

.hidden{
    display: none;
}

a.nav-link{
    font-size: 1.7rem;
}

nav > ul{
    width: 100%;
}

nav > ul > li{  
    list-style: none;
    display: inline-block;
	text-align: center;
    margin-top: 15px;
}

nav > ul > li > a{
    text-decoration: none;
    color: #F47320;
}

nav > ul > li > a:hover{
    text-decoration: underline;
    color: #9560FA;
}

.nav-brand{

}

/* ####################################################
                    END HEADER STYLES
#####################################################*/

/* ####################################################
                      HOME STYLES
#####################################################*/

.about{
    height: 78vh;
    min-height: 38vw;
    max-height: 600px;
    background-image: url(../img/bubbles1.png), url(../img/bubbles2.png);
    background-position: top -20vh left -10vh, bottom -15vh right -30vh;
    background-size: 30%, 50%;
    background-repeat: no-repeat;
    z-index: 3;
}

.about2{
    height: 78vh;
    min-height: 38vw;
    max-height: 600px;
    background-image: url(../img/gradient.png);
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    z-index: 3;
}

.about-img{
    border-radius: 40px;
    max-height: 23rem;
    width: auto;
}
.about-img2{
    border-radius: 20px;
    max-height: 23rem;
    width: auto;
}
.about-title{
    height: 8rem;
    width: auto;
}

.about-blurb{
    font-size: 1.2em;
    max-width: 550px;
    text-shadow: black;
}

.about-blurb2{
    font-size: 7.2em;
    color: #9560FA;
    outline: 1px;
    text-shadow: black;
    -webkit-text-fill-color: #9560FA; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.about-button{
    border-radius: 60px;
    background-color: #fdde6d5a;
    border-color: #DDBB71;
    border-width: 4px;
    width: 10em;
}

.music-container{
    background-color: black;
}

.music{
    height: 80vh;
    background-image: url(../img/fullband.png);
    background-size: cover;
    background-position: center;
}

.music-text{
    max-height: 10rem;
    width: auto;
}

.shows{
    height: auto;
    background-image: url(../img/flag.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.contact{
    height: 90vh;
    background-image: url(../img/comeandtakeitblue.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.contact-card-2{
    border-radius: 45px;
    background-color: rgba(255, 250, 228, 0.5);
    border-style: solid;
    border-color: #A78C53;
    border-width: 3px;
   
}

.contact-card-1{
    border-radius: 45px;
    background-color: rgba(255, 250, 228, 0.5);
    border-style: solid;
    border-color: #A78C53;
    border-width: 3px;
    max-width: 100%;
   
}

.contact-card-2{
    border-radius: 30px;
    background-color: rgba(255, 250, 228, 0.5);
    border-style: solid;
    border-color: #A78C53;
    border-width: 3px;
   max-width: 85%;
}

.modal-img{
    width: 100vw;
}

.word-break{
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}

/* ####################################################
                      END HOME STYLES
#####################################################*/

/* ####################################################
                      FOOTER STYLES
#####################################################*/

.footer-background{
    height: 75vh;
    background-image: url(../img/gradient.png);
    background-size: stretch;
    background-repeat: no-repeat;
    background-position: center;
}

.bubbles1{
    height: 100%;
    background-image: url(../img/bubbles1.png);
    background-position: center right -20vh;
    background-size: 30%, 50%;
    background-repeat: no-repeat;
    z-index: 3;
}

.bubbles3{
    background-image: url(../img/bubbles3.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom left;
    height: 50vw;
    max-height: 100%;
    min-width: 450px;
}

.bubbles-mobile{
    background-image: url(../img/bubbles3.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    height: 60vh;
    width: 300px;
    max-height: 400px;
}

.footer-map{
    font-size: 6rem;
    font-family: "mermaids";
}

.footer-logo{
    display: relative;
    max-height: 12.6vw;
    width: auto;
    margin-top: auto;
    margin-left: 6.2vw;
}

.footer-logo-mobile{
    display: relative;
    height: 20vw;
    margin-top: 20%;
    margin-left: 16%
}

.footer-link{
    font-size: 2.5rem;
}

.social-container{
    margin-bottom: 12.5vh;
    margin-left: 4vw;
    width: 20px;
    max-width: 27.5vw;
}

.mobile-social-container{
    margin-bottom: 12.5vh;
    margin-left: 9rem;
    max-width: 27.5vw;
    margin-top: -4.3rem;
}

.flex-logo-item{
    max-height: 3vw;
    min-height: 30px;
}
.mobile-flex-logo-item{
    max-height: 4.5vw;
    min-height: 30px;
}


/* ####################################################
                    END FOOTER STYLES
#####################################################*/


/* ####################################################
                      MEDIA QUERIES
#####################################################*/

/* Small devices (landscape phones, 576px and down)*/
@media (max-width: 575px) { 

    .about{
        height: auto;
        background-image: url(../img/bubbles1.png), url(../img/bubbles2.png);
        background-position: top -10vh left -6vh, bottom -9vh right -9vh;
        background-size: 50%, 80%;
        background-repeat: no-repeat;
        z-index: 3;
        max-height: none;
    }
    .about2{
        height: auto;
        min-height: 38vw;
        background-image: url(../img/gradient.png);
        background-position: center;
        background-size: fill;
        background-repeat: no-repeat;
        z-index: 3;
    }
    
    .about-img{
        border-radius: 60px;
        max-height: 19rem;
        width: auto;
    }
    .about-title{
        height: 6rem;
        width: auto;
    }
    
    .about-blurb{
        font-size: 1em;
        max-width: 550px;
    }
    
    .about-button{
        border-radius: 60px;
        background-color: #fdde6d5a;
        border-color: #DDBB71;
        border-width: 4px;
        width: 10em;
    }
    .contact{
        height: 100%;
        background-image: url(../img/comeandtakeitblue.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .contact-card-2{
        border-radius: 30px;
        background-color: rgba(255, 250, 228, 0.5);
        border-style: solid;
        border-color: #A78C53;
        border-width: 3px;
       max-width: 100%;
    }
    .music{
        height: 120%;
        background-image: url(../img/fullband.png);
        background-size: cover;
        background-position: center;
    }
    .footer-background{
        height: 100%;
        background-image: url(../img/gradient.png);
        background-size: stretch;
        background-repeat: no-repeat;
        background-position: 25%;
    }
    .footer-logo-mobile{
        display: relative;
        height: 25vw;
        margin-top: 18%;
        margin-left: 16%
    }
    .mobile-social-container{
        margin-bottom: 12.5vh;
        max-width: 55.5vw;
        margin-top: -15vw;
        margin-left: 3.5rem;;
    }
    .mobile-flex-logo-item{
        max-height: 6.5vw;
    }
    .floats{
        position: absolute;
        height: 100px;
        width: 100px;
        color: #9560FA;
        margin-top: -100px;

    }

 }

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) { 
    .floats{
        position: absolute;
        height: 100px;
        width: 100px;
        color: #9560FA;
        margin-top: -100px;

    }

    .about{
        height: 100%;
        background-image: url(../img/bubbles1.png), url(../img/bubbles2.png);
        background-position: top -10vh left -10vh, bottom -15vh right -30vh;
        background-size: 30%, 50%;
        background-repeat: no-repeat;
        z-index: 3;
        max-height: none;
    }
    
    .about-img{
        max-height: 19rem;
        width: auto;
    }
    .about-title{
        height: 6rem;
        width: auto;
    }
    
    .about-blurb{
        font-size: 1em;
        max-width: 550px;
    }
    
    .about-button{
        border-radius: 60px;
        background-color: #fdde6d5a;
        border-color: #DDBB71;
        border-width: 4px;
        width: 10em;
    }
    .music{
        height: 120%;
        background-image: url(../img/fullband.png);
        background-size: cover;
        background-position: center;
    }
    
    .music-text{
        max-height: 10rem;
        width: auto;
    }
    .contact{
        height: 100%;
        background-image: url(../img/comeandtakeitblue.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .footer-background{
        height: 100%;
        background-image: url(../img/gradient.png);
        background-size: stretch;
        background-repeat: no-repeat;
        background-position: 25%;
    }
    .mobile-social-container{
        margin-bottom: 12.5vh;
        max-width: 35.5vw;
    }
    
    .mobile-flex-logo-item{
        max-height: 6.5vw;
    }
    .mobile-social-container{
        margin-left: 5.3rem;
    }
    
    

 }

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) { 
    .about{
        height: 80vh;
        background-image: url(../img/bubbles1.png), url(../img/bubbles2.png);
        background-position: top -20vh left -10vh, bottom -15vh right -30vh;
        background-size: 30%, 50%;
        background-repeat: no-repeat;
        z-index: 3;
        
    }
    
    .about-img{
        max-height: 19rem;
        width: auto;
    }
    .about-title{
        height: 6rem;
        width: auto;
    }
    
    .about-blurb{
        font-size: 1em;
        max-width: 550px;
    }
    
    .about-button{
        border-radius: 60px;
        background-color: #fdde6d5a;
        border-color: #DDBB71;
        border-width: 4px;
        width: 10em;
    }
    .music{
        height: 100%;
        background-image: url(../img/fullband.png);
        background-size: cover;
        background-position: center;
    }
    
    .music-text{
        max-height: 10rem;
        width: auto;
    }
    .contact{
        height: 100%;
        background-image: url(../img/comeandtakeitblue.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    .contact-card-2{
        border-radius: 45px;
        background-color: rgba(255, 250, 228, 0.5);
        border-style: solid;
        border-color: #A78C53;
        border-width: 3px;
       
    }
    
    .contact-card-1{
        border-radius: 45px;
        background-color: rgba(255, 250, 228, 0.5);
        border-style: solid;
        border-color: #A78C53;
        border-width: 3px;
        max-width: 100%;
       
    }
    
    .contact-card-2{
        border-radius: 30px;
        background-color: rgba(255, 250, 228, 0.5);
        border-style: solid;
        border-color: #A78C53;
        border-width: 3px;
       max-width: 85%;
    }

    .contact-us{
        max-height: 5rem;
        width: auto;
    }
    .footer-map{
        font-size: 5rem;
        font-family: "mermaids";
    }
    .footer-link{font-size: 2rem;}
    .footer-background{
        height: 100%;
        background-image: url(../img/gradient.png);
        background-size: stretch;
        
        background-repeat: no-repeat;
        background-position: 25%;
    }
    .footer-logo-mobile{
        display: relative;
        height: 17vw;
        margin-top: 15%;
        margin-left: 21vh;
    }
    .mobile-social-container{
        margin-bottom: 12.5vh;
        margin-left: 10.5rem;
        max-width: 27.5vw;
        margin-top: -4.5rem;
    }
    
    
 }

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) { 
    .music{
        height: 100%;
        background-image: url(../img/fullband.png);
        background-size: cover;
        background-position: center;
    }
    
    .music-text{
        max-height: 10rem;
        width: auto;
    }
    .footer-background{
        height: 80vh;
        background-image: url(../img/gradient.png);
        background-size: stretch;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    
 }

 /* Large devices (desktops, 992px and up)*/
@media (min-width: 1025px) { 
    .about{
        height:80vh;
        background-image: url(../img/bubbles1.png), url(../img/bubbles2.png);
        background-position: top -20vh left -10vh, bottom -15vh right -30vh;
        background-size: 30%, 50%;
        background-repeat: no-repeat;
        z-index: 3;
    }
    
    .about-img{
        max-height: 23rem;
        width: auto;
    }
    .about-title{
        height: 8rem;
        width: auto;
    }
    
    .about-blurb{
        font-size: 1.2em;
        max-width: 550px;
    }
    
    .about-button{
        border-radius: 60px;
        background-color: #fdde6d5a;
        border-color: #DDBB71;
        border-width: 4px;
        width: 10em;
    }
    .music{
        height: 80vh;
        background-image: url(../img/fullband.png);
        background-size: cover;
        background-position: center;
    }
    
    .music-text{
        max-height: 10rem;
        width: auto;
    }
    .contact{
        height: 100%;
        background-image: url(../img/comeandtakeitblue.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    .contact-card-2{
        border-radius: 45px;
        background-color: rgba(255, 250, 228, 0.5);
        border-style: solid;
        border-color: #A78C53;
        border-width: 3px;
       
    }
    
    .contact-card-1{
        border-radius: 45px;
        background-color: rgba(255, 250, 228, 0.5);
        border-style: solid;
        border-color: #A78C53;
        border-width: 3px;
        max-width: 100%;
       
    }
    
    .contact-card-2{
        border-radius: 30px;
        background-color: rgba(255, 250, 228, 0.5);
        border-style: solid;
        border-color: #A78C53;
        border-width: 3px;
       max-width: 85%;
    }
    .footer-background{
        height: 80vh;
        background-image: url(../img/gradient.png);
        background-size: stretch;
        background-repeat: no-repeat;
        background-position: center;
    }
    .footer-map{
        font-size: 5rem;
        font-family: "mermaids";
    }
    
}

@media (min-width: 1090px) { 
    .floats{
        position: absolute;
        height: 100px;
        width: 100px;
        color: #9560FA;
        margin-top: -150px;

    }
}


/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) { 
    .footer-map{
        font-size: 6rem;
        font-family: "mermaids";
    }
    .footer-logo{
        display: relative;
        width: 23vw;
        max-width: 300px;
    }
 }


/* ####################################################
                      END MEDIA QUERIES
#####################################################*/
